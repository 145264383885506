import SectionHeader from "../SectionHeader/SectionHeader";
import "./About.css";
import AboutImg from "./about-1.png";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

function About(){
    return(
        <section className="about" id="about">
            <Container>
                <Row>

                    <Col lg="6" md={{ span: 12, order: "first" }}>
                        <div className="about-image">
                            <img src={AboutImg} alt="About Image"/>


                        </div>
                    </Col>
                    <Col lg="6" md={{ span: 12, order: "second" }}>
                        <div className="about-content">
                            <SectionHeader sectionTitle="Biz kimmiz?" sectionSubTitle="Sizning onlayn loyihalaringiz uchun ishonchli hamkor" />
                            <div className="about-text">
                                <p style={{textAlign:"justify"}}>
                                    UrinboyDev.uz - bu professional veb-saytlarni loyihalash, logotiplar va ijtimoiy media tarkiblari uchun grafik dizayn, shuningdek
                                    SEO optimallashtirishga ixtisoslashgan kompaniya. Sizning onlayn loyihalaringiz sizning biznesingiz muvaffaqiyatining kaliti
                                    ekanligiga ishonamiz va biz sizga onlayn maqsadlaringizga erishishda yordam berishga qaror qildik. Bizning tajribali va iste'dodli
                                    jamoamiz bilan sizga kuchli onlayn loyihalar yaratishga va boshqalardan ajralib turishga yordam beradigan yuqori sifatli xizmatlarni taklif etamiz.
                                    Sizga qanday yordam berishimiz mumkinligi haqida ko'proq bilish uchun bugun biz bilan bog'laning.
                                </p>
                                <strong>
                                    Biz sizning veb-saytingizni emas, balki sizning onlayn manzilingizni qurmoqdamiz.
                                </strong>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    );
}

export default About;