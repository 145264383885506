import {useState} from "react";
import {Form, Button, Alert} from "react-bootstrap";

const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const [formValues, setFormValues] = useState({
       name: "",
       email: "",
       subject: "",
       message: "",
    });

    const [showSuccess, setShowSuccess] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if(form.checkValidity() === false){
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if(form.checkValidity() === true){
            event.preventDefault();
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        }
    }

    const handleInputChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    }

     return(
         <Form noValidate validated={validated} onSubmit={handleSubmit} className="contact-form">

             <Form.Group controlId="formName">
                <Form.Control
                    required
                    type="text"
                    placeholder={"Ismingizni kiriting"}
                    name="name" value={formValues.name}
                    onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                    Iltimos ismingizni kiriting.
                </Form.Control.Feedback>
            </Form.Group>

             <Form.Group controlId="formEmail">
                 <Form.Control
                     required
                     type="email"
                     placeholder={"Elektron pochtangizni kiriting"}
                     name="email" value={formValues.email}
                     onChange={handleInputChange}
                 />
                 <Form.Control.Feedback type="invalid">
                     Iltimos, to'g'ri elektron pochta manzilini kiriting.
                 </Form.Control.Feedback>
             </Form.Group>

             <Form.Group controlId="formSubject">
                 <Form.Control
                     required
                     type="text"
                     placeholder={"Mavzuni kiriting"}
                     name="subject" value={formValues.subject}
                     onChange={handleInputChange}
                 />
                 <Form.Control.Feedback type="invalid">
                     Iltimos, mavzuni kiriting.
                 </Form.Control.Feedback>
             </Form.Group>

             <Form.Group controlId="formMessage">
                 <Form.Control
                     required
                     as="textarea"
                     rows="3"
                     placeholder={"Xabarni kiriting"}
                     name="message" value={formValues.message}
                     onChange={handleInputChange}
                 />
                 <Form.Control.Feedback type="invalid">
                     Iltimos, xabar kiriting.
                 </Form.Control.Feedback>
             </Form.Group>

             <Button variant="primary" type="submit" className="btn">
                 Yubormoq
             </Button>

             {showSuccess && (
                 <Alert variant="success" className="mt-3">
                     Xabaringiz muvaffaqiyatli yuborildi
                 </Alert>
             )}
         </Form>
     );
}

export default ContactForm;