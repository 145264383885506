import "./Portfolio.css";
import {Container, Row} from "react-bootstrap";
import SectionHeader from "../SectionHeader/SectionHeader";
import PortfolioItem from "./PortfolioItem";

function Portfolio(){
    return(
        <section className="portfolio" id="portfolio">
            <Container>
                <SectionHeader
                    Align="text-center"
                    sectionTitle="Bizning yutuqlarimiz"
                    sectionSubTitle="Mijozlarimiz uchun amalga oshirgan loyihalarimizni kashf eting"
                />
                <Row className="portfolio-container">
                    <PortfolioItem />
                </Row>
            </Container>
        </section>
    );
}

export default Portfolio;