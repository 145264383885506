import { Container, Row, Col } from "react-bootstrap";
import "./Hero.css";
import TypedText from "./TypetText";
import imgUdev from './udev.png';
import Buttons from "../Buttons/Buttons";
import SocialMedia from "../SocialMedia/Social";
import {UilInstagram, UilFacebookF, UilTelegramAlt, UilLinkedinAlt, UilGithubAlt} from "@iconscout/react-unicons";
import Particles from "react-tsparticles";
import {Fade, Zoom} from "react-reveal";

const Hero = () =>{
    const particlesInit = (main) => {
        console.log(main);
    }
    const particlesLoaded = (container) => {
        console.log(container);
    }

    return(
        <>
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    fpsLimit: 60,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            bubble:{
                                distance: 40,
                                duration: 2,
                                opacity: 0.8,
                                size: 40,
                            },
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            />
            <section className="hero" id="hero">
                <Container>
                    <Row className="hero-reverse">
                        <Col sm="12" md="7">
                            <div className="hero-content">
                                <div className="hero-text">
                                    <h3>Salom men</h3>
                                    <Zoom right cascade count={2} timeout={3000} mirro>
                                        <div className="hero-title">O'rinboy Tursunboyev</div>
                                    </Zoom>
                                    <Fade left>
                                        <h4><TypedText /> </h4>
                                    </Fade>
                                    <p className="hero-description">
                                        Bir qancha kirchik va katta loyihalarda qatnashib tajriba orttirganman.
                                        <br />Bataftis tanishish uchun men bilan bog'laning!
                                    </p>
                                </div>
                                <div className="hero-socials">
                                    <SocialMedia href="https://instagram.com/urinboydev" icon={<UilInstagram />} />
                                    <SocialMedia href="https://facebook.com/urinboydev" icon={<UilFacebookF />} />
                                    <SocialMedia href="https://t.me/urinboydev" icon={<UilTelegramAlt />} />
                                    <SocialMedia href="https://www.linkedin.com/in/urinboytursunboev" icon={<UilLinkedinAlt />} />
                                    <SocialMedia href="https://github.com/urinboy" icon={<UilGithubAlt />} />
                                </div>
                                <div className="hero-btn">
                                    <Buttons href="#contact" btnName="Bog'lanish uchun" />
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="5">
                            <div className="hero-image">
                                <img src={imgUdev} alt="Udev"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default Hero;