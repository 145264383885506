import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Service from "./components/Service/Service";
import Engagement from "./components/Engagaments/Engagement";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import ScrollUp from "./components/BackToTop/BackToTop";
import Preloader from "./components/PreLoader/Preloader";

function App() {
  return (
    <>
        <Preloader />
        <Header />
        <Hero />
        <About />
        <Service />
        <Engagement />
        <Portfolio />
        <Contact />
        <Footer />
        <ScrollUp />
    </>
  );
}

export default App;
