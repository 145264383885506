import "./Footer.css";
import flag from "./uzbekistan_128.gif";
import {Container} from "react-bootstrap";
 const currentYear = new Date().getFullYear();
 function Footer(){
     return(
         <footer className="footer">
             <Container fluid>
                 <Container className="copyright">
                    <p>
                        &copy; {currentYear} <a href="#">UrinboyDev.uz</a> | Made in <img src={flag} alt="Uzbekistan"/> Uzbekistan
                    </p>
                 </Container>
             </Container>
         </footer>
     );
 }

 export default Footer;