import {useState} from "react";
import "./Preloader.css";
import  logo from "./favicon.png";

const Preloader = () => {
    const [showLoader, setShowLoader] = useState(true);
    setTimeout(() => {
       setShowLoader(false);
    }, 2100);

    return showLoader ? (
        <div className="show" id="loader">
            <div className="loader">
                <img src={logo} alt="logo"/>
            </div>
        </div>
    ) : null;
}

export default Preloader;