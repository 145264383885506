import "./SectionHeader.css";
import Zoom from "react-reveal/Zoom";
function SectionHeader(props){
    return(
        <Zoom>
            <div className={props.Align}>
                <div className="section-header">
                    <h4>{props.sectionSubTitle}</h4>
                    <h2>{props.sectionTitle}</h2>
                </div>
            </div>
        </Zoom>
    );
}

export default SectionHeader;