import {useEffect, useState} from "react";
import {UilAngleUp} from "@iconscout/react-unicons";
import "./BackToTop.css";

const ScrollUp = () => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () =>{
            setScroll(window.scrollY);
        }
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }

    }, []);

    const showScroll = scroll >= 560;
    const scrolllUpClass = showScroll ? "show-scroll" : "";

    return(
        <a href="#" id="scroll-up" className={`scrollup btn ${scrolllUpClass}`}>
            <UilAngleUp className="scrollup_icon" />
        </a>
    );
    
}

export default ScrollUp;