import imgEngagement1 from "./img/1.png";
import imgEngagement2 from "./img/2.png";
import imgEngagement3 from "./img/3.png";
import imgEngagement4 from "./img/4.png";
import {Col} from "react-bootstrap";

const Engagements = [
    {
        src: imgEngagement1,
        alt: "Xizmat ko'rsatish sifati",
        Title: "Xizmat ko'rsatish sifati",
        Description: "Biz hajmi yoki byudjetidan qat'i nazar, barcha mijozlarimizga yuqori sifatli xizmat ko'rsatishga sodiqmiz. Biz mijozlarimiz bilan ularning ehtiyojlarini tushunish va ularga moslashtirilgan echimlarni taklif qilish uchun yaqindan ishlaymiz."
    },
    {
        src: imgEngagement2,
        alt: "Samarali hamkorlik",
        Title: "Samarali hamkorlik",
        Description: "Biz mijozlarimiz bilan ularning maqsadlarini tushunish va ularga onlayn maqsadlariga erishishga yordam beradigan echimlarni taklif qilish uchun yaqindan ishlashga sodiqmiz."
    },
    {
        src: imgEngagement3,
        alt: "Tezlik va ishonchlilik",
        Title: "Tezlik va ishonchlilik",
        Description: "Biz tez va ishonchli xizmatni taqdim etishga sodiqmiz. Onlayn loyihalar mijozlarimizning muvaffaqiyati uchun muhim bo'lishi mumkinligini bilamiz va ularni tez va ishonchli etkazib berishga intilamiz."
    },
    {
        src: imgEngagement4,
        alt: "Qoniqish kafolatlangan",
        Title: "Qoniqish kafolatlangan",
        Description: "Biz barcha mijozlarimizga kafolatlangan qoniqishni ta'minlashga sodiqmiz. Biz o'tmishdagi yutuqlarimiz bilan faxrlanamiz va kelajakda sifatli xizmatlarni ko'rsatishda davom etamiz. Agar mijozlarimiz qoniqmasa, biz ular bilan bo'lguncha ishlaymiz."
    },
];


const EngagementItem = () => {
    return(
        <>
            {
                Engagements.map((Engagement, index) => (
                    <Col lg="6">
                        <div className="engagement-item">
                            <img src={Engagement.src} alt={Engagement.alt}/>
                            <div className="engagement-text">
                                <h3>{Engagement.Title}</h3>
                                <p>{Engagement.Description}</p>
                            </div>
                        </div>
                    </Col>
                ))
            }
        </>
    );
}

export default EngagementItem;