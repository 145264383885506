import {
    phoneNumber,
    phoneNumberLink,
    email,
    emailLink,
    instagramLink,
    facebookLink,
    telegramLink,
    linkedinLink,
    githubLink

} from "./ContactInfo";
import Lottie from "react-lottie";
import {
    UilPhone,
    UilEnvelopes,
    UilFacebookF,
    UilTelegramAlt,
    UilLinkedinAlt,
    UilGithubAlt,
    UilInstagram
} from "@iconscout/react-unicons";
import ContactForm from "./ContactForm";
import SectionHeader from "../SectionHeader/SectionHeader";
import {Col, Container, Row} from "react-bootstrap";
import "./Contact.css";
import ContactAnimation from "./contact-animation.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ContactAnimation,
    renderSetting: {
        preserveAspectRatio: "xMidYmid meet"
    },
};

function Contact() {
    return(
        <section className="contact" id="contact">
            <Container>
                <SectionHeader
                    Align="text-center"
                    sectionTitle="Biz bilan bog'lanishdan tortinmang"
                    sectionSubTitle="Agar sizda biron bir savol bo'lsa yoki loyihangizni muhokama qilmoqchi bo'lsangiz. Biz sizga yordam berish uchun keldik." />
                <Row>
                    <Col md="6" sm="12" className="contact-info">
                        <Lottie options={defaultOptions} height={180} />
                        <div className="contact-menu">
                            <a href={emailLink}><UilEnvelopes/> {email} </a>
                            <a href={phoneNumberLink}><UilPhone/> {phoneNumber} </a>
                        </div>
                        <div className="contact-social">
                            <a href={instagramLink}><UilInstagram/></a>
                            <a href={facebookLink}><UilFacebookF/></a>
                            <a href={telegramLink}><UilTelegramAlt/></a>
                            <a href={linkedinLink}><UilLinkedinAlt/></a>
                            <a href={githubLink}><UilGithubAlt/></a>
                        </div>
                    </Col>
                    <Col md="6" sm="12">
                        <ContactForm/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default Contact;