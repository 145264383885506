import Lottie from "react-lottie";
import { Container, Row } from "react-bootstrap";
import "./Service.css";
import SectionHeader from "../SectionHeader/SectionHeader";
import {
    UilLaptop,
    UilPen,
    UilSearchAlt,
    UilChartLine
} from "@iconscout/react-unicons";
import ServiceItem from "./ServiceItem";
import ServiceAnimation from "./animation_prog.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ServiceAnimation,
    renderSetting: {
        preserveAspectRatio: "xMidYmid meet"
    },
};

function Service(){
    return(
        <section className="service" id="service">
            <Container>
                <SectionHeader
                    Align="text-center"
                    sectionTitle="Xizmatlarimizni kashf eting"
                    sectionSubTitle="Onlayn muvaffaqiyatingiz shu yerdan boshlanadi"
                />

                <Row>
                    <ServiceItem
                        serviceIcon={<UilLaptop />}
                        serviceTitle="VEB-SAYT DIZAYNI"
                        serviceDescription="Sizga kuchli onlayn ishtirok etishni yaratishda yordam beradigan professional veb-sayt dizaynini taklif etamiz. Biz sizning ehtiyojlaringizni tushunish va sizning biznesingizni izchil va jozibali tarzda namoyish etadigan veb-sayt yaratish uchun siz bilan ishlamoqdamiz."
                    />
                    <ServiceItem
                        serviceIcon={<UilPen />}
                        serviceTitle="GRAFIK DIZAYN"
                        serviceDescription="Biz biznes uchun professional logotiplarni, shuningdek ijtimoiy tarmoqlar va onlayn reklama kampaniyalari uchun dizaynlarni yaratamiz. Onlayn brendingizni izchil izlashni ishlab chiqish uchun biz siz bilan ishlamoqdamiz."
                    />
                    <ServiceItem
                        serviceIcon={<UilSearchAlt />}
                        serviceTitle="TABIIY HAVOLALAR"
                        serviceDescription="Biz SEO-ni optimallashtirishning samarali strategiyalari orqali kompaniyalarga ularning onlayn ko'rinishini yaxshilashga yordam beramiz. Tabiiy havolalar muvaffaqiyatli veb-saytning kaliti ekanligiga ishonamiz va sizning onlayn maqsadlaringizga erishishda sizga yordam berishga qaror qildik."
                    />
                    <ServiceItem
                        serviceIcon={<UilChartLine />}
                        serviceTitle="TARKIB STRATEGIYASI"
                        serviceDescription="Biz kompaniyalarga ularning onlayn ishtirokini yaxshilash uchun samarali tarkibiy strategiyani ishlab chiqishda yordam bermoqdamiz. Biz sizning maqsadlaringizni tushunish va ularni qo'llab-quvvatlaydigan tarkibni, shu jumladan ijtimoiy tarmoqlarda bloglar va maqolalarni yaratish uchun siz bilan ishlamoqdamiz."
                    />
                </Row>
                <Lottie options={defaultOptions} height={350} style={{ margin: "-7% 0" }} />
            </Container>
        </section>
    );
}

export default Service;