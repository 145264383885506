import {Container, Row} from "react-bootstrap";
import SectionHeader from "../SectionHeader/SectionHeader";
import EngagementItem from "./EngagementItem";
import "./Engagement.css";

function Engagement(){
    return(
        <section className="engagement" id="engagement">
            <Container>
                <SectionHeader
                    Align="text-center"
                    sectionTitle="Mijozlarimiz oldidagi majburiyatlarimiz"
                    sectionSubTitle="Sifat, hamkorlik, tezlik va qoniqishni ta'minlash"
                />
                <div className="engagement-items">
                    <Row>
                        <EngagementItem />
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default Engagement;