import Container from "react-bootstrap/Container";
import {UilEstate, UilUser, UilMultiply, UilApps, UilSuitcaseAlt, UilImage, UilMessage, UilLayerGroup} from "@iconscout/react-unicons";
import logo from "./logo.png";
import "./Header.css";
import {useEffect, useState} from "react";
import Bounce from "react-reveal/Bounce";

const sections = [
    { id: "hero", icon: UilEstate, text: "Asosiy"},
    { id: "about", icon: UilUser, text: "Biz haqimizda"},
    { id: "service", icon: UilSuitcaseAlt, text: "Xizmatlar"},
    { id: "engagement", icon: UilLayerGroup, text: "Majburiyatlarimiz"},
    { id: "portfolio", icon: UilImage, text: "Portfolio"},
    { id: "contact", icon: UilMessage, text: "Bog'lanish"},
];

function Header(){
    const [menuClass, setMenuClass] = useState("");
    const [logoClass, setLogoClass] = useState("")
    const [scrollY, setScrollY] = useState(0);
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        const sections = document.querySelectorAll("[id]");

        const handleScroll = () => {
            const scrollY = window.pageYOffset;

            sections.forEach((current) => {
                const sectionHeight = current.offsetHeight;
                const sectionTop = current.offsetTop -250;
                const sectionId = current.getAttribute("id");

                if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight){
                    setActiveSection(sectionId);
                }
            });
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    

    useEffect(() => {
        function handleScroll(){
            setScrollY(window.pageYOffset);
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);
    const handleToggle = () => {
        setMenuClass("show-menu");
        setLogoClass("hide");
    }
    const handleClose = () => {
        setMenuClass("");
    }
    const handleLinkClick = () => {
        setMenuClass("")
    }

    const headerClass = scrollY >= 80 ? "scroll-header" : "";

    return (
        <header className={`header ${headerClass}`} id="header">
            <Container style={{padding: "0"}}>
                <nav className="nav">
                    <Bounce top delay={500}>
                        <a href="/" id="nav-logo" className={`nav_logo ${logoClass}`}>
                            <img src={logo} alt="Logo"/>
                            UrinboyDev.uz
                        </a>
                    </Bounce>
                    <div className={`nav_menu ${menuClass}`} id="nav-menu">
                        <UilMultiply id="nav-close" className="nav_close" onClick={handleClose}/>
                        <ul className="nav_list grid">
                             {sections.map((section) =>(
                                 <li className="nav_item" key={section.id}>
                                     <a href={`#${section.id}`} className={`nav_link ${
                                         activeSection === section.id ? "active-link" : ""
                                     }`} onClick={handleLinkClick}>
                                         <section.icon className="nav_icons"/>
                                         {section.text}
                                     </a>
                                 </li>
                                 ))}
                        </ul>
                    </div>
                    <div className="nav_btns">
                        <div className="nav_toggle" id="nav-toggle" onClick={handleToggle}>
                            <UilApps />
                        </div>
                    </div>
                </nav>
            </Container>
        </header>
    );
}

export default Header;