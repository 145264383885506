import Typed from "react-typed";
function TypedText(){
    return(
        <div className="typed-text">
            { }
            <span style={{fontWeight: "bold"}}>
                <Typed strings={["Front-end Dasturchiman", "Backend Dasturchiman", "Flutter Junior Dasturchiman", "FullStack Veb Dasturchiman"]} typeSpeed={50} backSpeed={50} loop />
            </span>
        </div>
    );
}

export default TypedText;