import {Col} from "react-bootstrap";
import {UilLaptop} from "@iconscout/react-unicons";

function ServiceItem(props){
    return(
        <Col lg="6" sm="12">
            <div className="service-item">
                <div className="service-icon">
                    <i>{props.serviceIcon}</i>
                </div>
                <div className="service-text">
                    <h3>{props.serviceTitle}</h3>
                    <p>{props.serviceDescription}</p>
                </div>
            </div>

        </Col>
    );
}

export default ServiceItem;