import {useState} from "react";
import Col from "react-bootstrap/Col";
import ImagePortfolio1 from "./img/portfolio-1.png";
import ImagePortfolio2 from "./img/portfolio-2.png";
import ImagePortfolio3 from "./img/portfolio-3.png";
import ImagePortfolio4 from "./img/portfolio-4.png";
import {UilArrowRight, UilTimes, UilAngleLeft, UilAngleRight} from "@iconscout/react-unicons";
import Slide from "react-reveal/Slide";
const portfolios = [
    {
        src: ImagePortfolio1,
        alt: "Veb-sayt dizayni",
        Title: "Veb-sayt dizayni",
        linkText: "veb-saytni ko'ring",
        linkUrl: "https://new.urinboydev.uz"
    },
    {
        src: ImagePortfolio2,
        alt: "Veb-sayt dizayni",
        Title: "Veb-sayt dizayni",
        linkText: "veb-saytni ko'ring",
        linkUrl: "https://urinboydev.uz"
    },
    {
        src: ImagePortfolio3,
        alt: "Veb-sayt dizayni",
        Title: "Veb-sayt dizayni",
        linkText: "veb-saytni ko'ring",
        linkUrl: "https://urinboy.github.io/WebForte/index.html"
    },
    {
        src: ImagePortfolio4,
        alt: "Veb-sayt dizayni",
        Title: "Veb-sayt dizayni",
        linkText: "veb-saytni ko'ring",
        linkUrl: "https://new-adep-2022.netlify.app/"
    },
];

const PortfolioItem = () =>{
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setLightboxIsOpen(true);
        document.body.style.overflow = "hidden";
    };
    const closeLightbox = () => {
          setLightboxIsOpen(false);
          document.body.style.overflow = "auto";
    };
    const goToNextImage = () => {
        setCurrentImage((currentImage + 1) % portfolios.length);
    };
    const goToPrevImage = () => {
        setCurrentImage((currentImage + portfolios.length - 1) % portfolios.length);
    };

    return(
        <>
            {portfolios.map((item, index) =>(
                <Col lg="3" md="6" sm="12">
                    <div className="portfolio-wrap">
                        <div className="portfolio-image">
                            <img src={item.src} alt={item.alt} key={item.alt} onClick={() => openLightbox(index)}/>
                            <div className="portfolio-text">
                                <p>
                                    {item.Title} <br />
                                    <a href={item.linkUrl} target="_blank" rel="noreferrer">{item.linkText} { item.linkText ?  <UilArrowRight /> : null }</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            ))}
            {lightboxIsOpen && (
                    <Lightbox
                        image={portfolios[currentImage]}
                        closeLightbox={closeLightbox}
                        goToNextImage={goToNextImage}
                        goToPrevImage={goToPrevImage}
                    />)
            }
        </>
    );
};

const Lightbox = ({image, closeLightbox, goToNextImage, goToPrevImage}) => (
    <Slide bottom>
        <div className="lightbox">
            <img src={image.src} alt={image.alt}/>
            <button className="close" onClick={closeLightbox}>
                <UilTimes />
            </button>
            <button className="prev" onClick={goToPrevImage}>
                <UilAngleLeft />
            </button>
            <button className="next" onClick={goToNextImage}>
                <UilAngleRight />
            </button>
        </div>
    </Slide>
);

export default PortfolioItem;